import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/ClassAssembly/6D/1.jpg';
// import p2 from 'assests/Photos/ClassAssembly/6D/2.jpg';
// import p3 from 'assests/Photos/ClassAssembly/6D/3.jpg';
// import p4 from 'assests/Photos/ClassAssembly/6D/4.jpg';
// import p5 from 'assests/Photos/ClassAssembly/6D/5.jpg';
// import p6 from 'assests/Photos/ClassAssembly/6D/6.jpg';
// import p7 from 'assests/Photos/ClassAssembly/6D/7.jpg';





import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly3E/components/SidebarArticles/SidebarArticles';

const ClassAssembly6D = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/class-assembly/6D/1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/class-assembly/6D/2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/class-assembly/6D/3.webp`;
  const p4 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/class-assembly/6D/4.webp`;
  const p5 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/class-assembly/6D/5.webp`;
  const p6 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/class-assembly/6D/6.webp`;
  const p7 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/class-assembly/6D/7.webp`;
   

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 2,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },

      {
        src: p7,
        source: p7,
        rows: 1,
        cols: 2,
      },

    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      World Radio Day
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 6D Date: 7 February 2023

        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Students of Class 6D presented an informative assembly on the topic, ‘World Radio Day’ – on Tuesday,
7 February 2023. The assembly highlighted the significance of Radio and students spoke about the
invention of the radio.


 <br></br>
 The students interspersed their presentation with a skit and a dance. The skit emphasised the
importance of the radio and various programmes broadcasted on it. The students performed a
beautiful dance pertaining to the World Radio Day and conducted an informative quiz.
        <br></br>
        The assembly conveyed that Radio is the medium that connects different cultures and diversities to
weave us all together in one thread. No matter what your age is, no matter where you live, you can
always connect with the outside world through the radio the largest means of Mass Communication.
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
  A Radio is not a powerful device because of its microphone, it
is because of the person who sits behind the microphone


<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly6D;